<template>
  <div v-loading="loading" class="layout mt-4">
    <div
      class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
      v-if="invoices.length > 0"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        Overdue Invoices
      </h3>
      <div class="flex">
        <div class="mt-3 sm:mt-0 sm:ml-4 sm:mr-4">
          <label for="search_invoice" class="sr-only">Search</label>
          <div class="flex rounded-md shadow-sm">
            <div class="relative flex-grow focus-within:z-10">
              <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
              >
                <SearchIcon class="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                v-model="search"
                name="search_invoice"
                id="search_invoice"
                class="search block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 sm:hidden 2xl:focus:ring-indigo-500"
                placeholder="Search"
              />
              <input
                type="text"
                name="search_invoice"
                id="search_invoice"
                class="search hidden w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:block sm:text-sm"
                placeholder="Search"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col" v-if="invoices.length > 0">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <pagination :pagination="pagination" @changePage="onChangePage" />
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  ></th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Customer
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Due
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Created
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody
                class="divide-y divide-gray-200 bg-white"
                v-if="invoices.length > 0"
              >
                <tr v-for="invoice in invoices" :key="invoice.Id">
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      ${{ invoice.AmountDue }} USD
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      <span
                        class="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800"
                      >
                        Outstanding
                      </span>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm font-medium text-gray-900">
                      {{ invoice.Customer.Name }}
                    </div>
                    <div class="max-w-sm truncate text-sm text-gray-500">
                      {{ invoice.Customer.Email }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      {{
                        new Date(invoice.DueDate).toLocaleDateString("en-us", {
                          weekday: "long",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      {{
                        new Date(invoice.Created).toLocaleDateString("en-us", {
                          weekday: "long",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-empty
        description="Hooray, no outstanding invoices"
        :image-size="200"
      ></el-empty>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";

import useInvoicesRepository from "@/composable/useInvoicesRepository";
import Pagination from "@/common/components/pagination";

import { SearchIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Pagination,
    SearchIcon,
  },
  setup() {
    const { loading, invoices, totalResults, getOverdueInvoices } =
      useInvoicesRepository();

    const pagination = reactive({
      page: 1,
      pageSize: 10,
      totalResults: totalResults,
    });

    onMounted(() => {
      getOverdueInvoices(pagination.page, pagination.pageSize);
    });

    const onChangePage = (page) => {
      pagination.page = page;
      getOverdueInvoices(pagination.page, pagination.pageSize);
    };

    return {
      loading,
      invoices,
      pagination,
      onChangePage,
    };
  },
};
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  /* overflow-y: scroll; */
  width: 100%;
  position: relative;
}
</style>
