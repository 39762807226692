import { ref, readonly } from "vue";

import API from "@/api";
import { ElNotification } from "element-plus";

export default function useCustomersRepository() {
  const taxRates = ref([]);
  const loading = ref(false);

  const getTaxRates = async () => {
    loading.value = true;
    let response = await API.Organization.TaxRates.get();

    if (response.data.Success) {
      taxRates.value = response.data.List;
      loading.value = false;
    } else {
      ElNotification({
        title: "Oops",
        message: "Problem while fetching tax rates",
        type: "warning",
      });
    }
  };

  const saveTaxRates = async (taxRate) => {
    let response = await API.Organization.TaxRates.save(taxRate);

    if (response.data.Success) {
      ElNotification({
        title: "Success",
        message: "New Tax Rate created",
        type: "success",
      });
      return response;
    }
  };

  return {
    loading: readonly(loading),
    taxRates: readonly(taxRates),
    getTaxRates,
    saveTaxRates,
  };
}
