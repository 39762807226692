<template>
  <TransitionRoot appear as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 flex max-w-full pl-10">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl"
              >
                <div class="bb px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="pl-6 text-lg font-medium text-gray-900">
                      Create New Customer
                    </DialogTitle>
                    <div class="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        class="focus:outline-none rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        @click="onClose"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="relative mt-6 flex-1 px-4 sm:px-6"
                  v-loading="loading"
                >
                  <!-- Replace with your content -->
                  <form class="flex h-full flex-col bg-white">
                    <div class="flex flex-1 flex-col justify-between">
                      <div class="divide-y divide-gray-200 px-4 sm:px-6">
                        <div class="space-y-6 pt-6 pb-5">
                          <div>
                            <label
                              for="project-name"
                              class="block text-sm font-medium text-gray-900"
                            >
                              Name
                            </label>
                            <div class="mt-1">
                              <input
                                type="text"
                                name="customer-name"
                                id="customer-name"
                                v-model="state.name"
                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              for="project-name"
                              class="block text-sm font-medium text-gray-900"
                            >
                              Description
                            </label>
                            <div class="mt-1">
                              <textarea
                                id="description"
                                name="description"
                                v-model="state.description"
                                rows="4"
                                class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              for="project-name"
                              class="block text-sm font-medium text-gray-900"
                            >
                              Phone
                            </label>
                            <div class="mt-1">
                              <input
                                type="text"
                                name="customer-phone"
                                id="customer-phone"
                                v-model="state.phone"
                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              for="project-name"
                              class="block text-sm font-medium text-gray-900"
                            >
                              Email
                            </label>
                            <div class="relative mt-1 rounded-md shadow-sm">
                              <input
                                type="text"
                                name="customer-email"
                                id="customer-email"
                                v-model="state.email"
                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              for="Invoice-Prefix"
                              class="flex justify-between text-sm font-medium text-gray-900"
                            >
                              <div>
                                Invoice Prefix
                                <span class="text-gray-500"> (optional) </span>
                              </div>
                              <ShowInfo
                                text="Use a unique prefix for each customer and assigns invoice numbers sequentially for a given customer"
                              />
                            </label>
                            <div class="mt-1">
                              <input
                                type="text"
                                name="prefix"
                                maxlength="4"
                                v-model="state.prefix"
                                class="block w-full rounded-md border-gray-300 uppercase shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        @click="onClose"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        @click="onSubmit"
                        class="focus:outline-none ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, reactive, toRefs } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import ShowInfo from "@/common/components/showInfo";
import useCustomersRepository from "@/composable/useCustomersRepository";
import { ElMessage } from "element-plus";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ShowInfo,
    XIcon,
  },
  emits: ["customerCreated", "onClose"],
  setup(_, { emit }) {
    const open = ref(true);
    const { saveCustomerAndReturnValue, loading } = useCustomersRepository();

    const state = reactive({
      name: "",
      description: "",
      phone: "",
      email: "",
      prefix: "",
    });

    const onSubmit = async () => {
      if (!state.name || !state.phone || !state.email) {
        return ElMessage.error("Please fill all least Name and Email");
      }

      let response = await saveCustomerAndReturnValue({
        name: state.name,
        description: state.description,
        phone: state.phone,
        email: state.email,
        prefix: state.prefix,
      });

      if (response.status === 200) {
        emit("customerCreated", response.data.Item);
        state.name = "";
        state.description = "";
        state.phone = "";
        state.email = "";
        state.prefix = "";
      }
    };

    const onClose = () => {
      emit("onClose", true);
    };

    return {
      state,
      open,
      loading,
      onClose,
      onSubmit,
      saveCustomerAndReturnValue,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped></style>
