<template>
  <CustomerSelect
    :newCustomer="state.newCustomer"
    @customer="customer"
    @openSlideOver="openSlide"
  />
  <CreateNewCustomer
    :show="state.open"
    @onClose="closeSlide"
    @customerCreated="handleCustomerCreated"
  />
</template>

<script>
import { defineComponent, reactive } from "vue";

import CustomerSelect from "./_select";
import CreateNewCustomer from "./_newCustomer.vue";

export default defineComponent({
  components: {
    CustomerSelect,
    CreateNewCustomer,
  },
  emits: ["selectedCustomer", "customer"],
  setup(_, { emit }) {
    const state = reactive({
      open: false,
      newCustomer: {},
    });

    const openSlide = () => {
      state.open = true;
    };

    const closeSlide = () => {
      state.open = false;
    };

    const customer = (item) => {
      emit("selectedCustomer", item);
    };

    const onCreateCustomer = (customer) => {
      state.newCustomer = customer;
    };

    const handleCustomerCreated = (customer) => {
      state.newCustomer = customer;
      emit("selectedCustomer", customer);
      closeSlide();
    };

    return {
      state,
      openSlide,
      closeSlide,
      customer,
      onCreateCustomer,
      handleCustomerCreated,
    };
  },
  methods: {},
});
</script>
