<template>
  <el-select
    v-model="customer"
    filterable
    clearable
    placeholder="Find or add a customer"
  >
    <el-option key="new-customer" value="NewCustomer"
      ><span class="flex"
        ><PlusIcon
          class="space -ml-1 mr-3 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
        />
        <span>Add new customer</span></span
      ></el-option
    >
    <div v-if="customers.length > 0" class="devider"></div>
    <el-option
      v-for="item in customers"
      :key="item.Id"
      :label="item.Name"
      :value="item.Id"
      v-loading="loading"
    >
      <div class="flex justify-between">
        <span>{{ item.Name }}</span>
        <div class="flex justify-between">
          <span class="text-gray-300 hover:border-gray-100 hover:text-gray-500"
            >{{ item.Email }}
          </span>
        </div>
      </div>
    </el-option>
  </el-select>
</template>

<script>
import { reactive, toRefs, onMounted, watch, inject } from "vue";
import useCustomersRepository from "@/composable/useCustomersRepository";

import { PlusIcon } from "@heroicons/vue/outline";

export default {
  components: {
    PlusIcon,
  },
  props: {
    newCustomer: {
      type: Object,
    },
  },
  emits: ["customer", "openSlideOver", "onCustomer"],
  setup(props, { emit }) {
    const { loading, allCustomers, getAllCustomers } = useCustomersRepository();
    const newInvoice = inject("new-invoice-created");

    const state = reactive({
      customer: "",
    });

    onMounted(() => {
      // todo : create infinite scroll
      getAllCustomers();
    });

    watch(
      () => state,
      async (state) => {
        if (state.customer === "NewCustomer") {
          emit("openSlideOver", true);
          state.customer = "";
        } else if (state.customer) {
          await getAllCustomers();
          let customer = findByID(state.customer);
          emit("customer", customer);
        }
      },
      { deep: true }
    );

    watch(
      () => newInvoice,
      (newInvoice) => {
        if (newInvoice) {
          state.customer = "";
        }
      },
      { deep: true }
    );

    watch(
      () => props.newCustomer,
      async (newCustomer) => {
        console.log({ newCustomer });
        if (newCustomer) {
          await getAllCustomers();
          state.customer = newCustomer.Id;
        }
      },
      { deep: true }
    );

    const findByID = (id) => {
      return allCustomers.value.find((item) => item.Id === id);
    };

    return {
      loading,
      customers: allCustomers,
      findByID,
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
.space {
  margin: 8px 8px 0 0px;
}

.devider {
  border-top: 1px solid #ebebeb;
  margin: 8px 0;
}
</style>
