<template>
  <div class="layout">
    <TabGroup @change="changedTab">
      <TabList class="border-b border-gray-200 text-left">
        <Tab
          :class="[
            tab.current
              ? 'border-indigo-500 text-indigo-600'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
            'ml-2 mr-2 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
          ]"
          :aria-current="tab.current ? 'page' : undefined"
          v-for="tab in tabs"
          :key="tab"
          :to="tab.route"
          >{{ tab.name }}</Tab
        >
      </TabList>
      <TabPanels>
        <TabPanel><New /></TabPanel>
        <TabPanel><Draft /></TabPanel>
        <TabPanel><Outstanding /></TabPanel>
        <TabPanel><Sent /></TabPanel>
        <TabPanel><Unpaid /></TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { ref } from "vue";
// import { useRouter, onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";

import New from "./new";
import Draft from "./draft";
import Outstanding from "./outstanding";
import Sent from "./sent";
import Unpaid from "./unpaid";

export default {
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    New,
    Draft,
    Outstanding,
    Sent,
    Unpaid,
  },
  setup() {
    //const router = useRouter();

    const tabs = ref([
      {
        name: "New",
        url: "new",
        index: 0,
        current: true,
      },
      {
        name: "Draft",
        url: "draft",
        index: 1,
        current: false,
      },
      {
        name: "Outstanding",
        url: "outstanding",
        index: 2,
        current: false,
      },
      {
        name: "Sent",
        url: "sent",
        index: 3,
        current: false,
      },
      {
        name: "Unpaid",
        url: "unpaid",
        index: 4,
        current: false,
      },
    ]);

    const changedTab = (index) => {
      tabs.value.forEach((tab) => {
        tab.current = tab.index === index;
      });
    };

    return {
      tabs,
      changedTab,
    };
  },
};
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  overflow-y: hidden;
  width: 100%;
  position: relative;
}
</style>
