<template>
  <div v-loading="loading">
    <div class="flex flex-row-reverse pt-4">
      <button
        type="button"
        @click="state.toggleSendModal = true"
        :disabled="customer.id === null && items.length === 0"
        :class="{
          'cursor-not-allowed opacity-25': !isFormReady,
        }"
        class="order-0 inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm sm:order-1 sm:ml-3"
      >
        Send Invoice
      </button>
      <button
        type="button"
        :disabled="customer.id === null && items.length === 0"
        :class="{
          'cursor-not-allowed opacity-25': !isFormReady,
        }"
        @click="state.toggleSaveModal = true"
        class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:order-1 sm:ml-3"
      >
        Save as Draft
      </button>
    </div>
    <div class="invoice">
      <div class="form">
        <div class="form--item customer">
          <p class="header">Customer</p>
          <Customers
            @selectedCustomer="selectedCustomer"
            v-if="renderComponent"
          />
        </div>

        <div class="form--item items">
          <p class="header">Items</p>
          <Items @selectedItems="selectedItems" v-if="renderComponent" />
        </div>

        <div class="form--item items">
          <p class="header">Memo</p>
          <el-input
            v-model="memo"
            :rows="2"
            type="textarea"
            placeholder="Thanks for your business!"
          />
        </div>

        <div class="form--item items">
          <!-- <p class="header">Payment</p> -->
          <el-checkbox
            v-model="checked1"
            disabled
            label="Email invoice to customer with link to payment page"
          ></el-checkbox>
          <div>
            <div>
              <p class="text-left">Due date</p>
              <div class="flex">
                <el-select
                  v-model="dueDate"
                  placeholder="Select"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in dueDates"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-date-picker
                  v-if="dueDate === 'Custom'"
                  v-model="customDate"
                  type="date"
                  placeholder="Pick a day"
                  class="ml-2"
                  :disabled-date="disabledDate"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="grid">
              <p class="mt-4 text-left">Tax Rate</p>
              <div class="flex">
                <el-select
                  v-model="taxRate"
                  placeholder="Select"
                  style="width: 180px"
                  multiple
                  collapse-tags
                >
                  <el-option key="new-tax-rate" value="new-tax-rate">
                    <span class="flex"
                      ><PlusIcon
                        class="space mt-2 -ml-1 mr-3 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      />
                      <span>Add new Tax Rate</span></span
                    >
                  </el-option>
                  <div v-if="taxRates.length > 0" class="devider"></div>
                  <el-option
                    v-for="item in taxRates"
                    :key="item.id"
                    :label="item.display_name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <div v-if="state.toggleNewTaxRate" class="ml-4 flex">
                  <input
                    type="text"
                    style="width: 120px"
                    v-model="newTaxRate"
                    placeholder="Add Tax Rate"
                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  <button
                    type="button"
                    @click="onNewTaxRate"
                    class="focus:outline-none ml-2 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div class="mt-4 flex flex-col">
              <label for="amountPaid" class="text-left">Amount Paid</label>
              <el-input-number
                v-model="amountPaid"
                :disabled="items.length === 0"
                :min="0"
                @change="handleAmountPaid"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="preview">
        <p class="header">Preview</p>

        <Preview
          :memo="memo"
          :dueDate="dueDate"
          :customDate="customDate"
          :items="previewItems"
          :customer="customer"
          :taxRates="taxRateItems"
          :amountPaid="amountPaid"
        />
      </div>
    </div>
    <SendModal
      v-if="state.toggleSendModal"
      :open="state.toggleSendModal"
      @onClose="onSendClose"
      @onSubmit="onSendSubmit"
    />
    <SaveAsDraftModal
      v-if="state.toggleSaveModal"
      :open="state.toggleSaveModal"
      @onClose="onSaveClose"
      @onSubmit="onSaveSubmit"
    />
  </div>
</template>

<script>
import {
  ref,
  watch,
  reactive,
  provide,
  readonly,
  defineComponent,
  computed,
  onMounted,
  nextTick,
} from "vue";

import { PlusIcon } from "@heroicons/vue/outline";

import useInvoicesRepository from "@/composable/useInvoicesRepository";
import useTaxRates from "@/composable/useTaxRates";

import Preview from "./preview";
import SendModal from "./modals/_sendModal.vue";
import SaveAsDraftModal from "./modals/_saveAsDraftModal.vue";

import Customers from "./customers";
import Items from "./items";

export default defineComponent({
  components: {
    PlusIcon,
    Items,
    Preview,
    SendModal,
    Customers,
    SaveAsDraftModal,
  },
  setup() {
    const renderComponent = ref(true);
    const { loading, send, saveAsDraft } = useInvoicesRepository();
    const { taxRates, getTaxRates, saveTaxRates } = useTaxRates();
    const newInvoiceCreated = ref(false);

    const state = reactive({
      toggleSaveModal: false,
      toggleSendModal: false,
      toggleNewTaxRate: false,
    });

    const disabledDate = (time) => {
      const currentDate = new Date();
      // Set the time to 00:00:00 for accurate comparison
      currentDate.setHours(0, 0, 0, 0);

      // Convert the provided time to a Date object
      const selectedDate = new Date(time);

      // Return true if the selected date is on or after today
      return selectedDate <= currentDate;
    };

    const dueDates = ref([
      {
        value: new Date(
          Date.now() + 1000 /*sec*/ * 60 /*min*/ * 60 /*hour*/ * 24 /*day*/ * 1
        ),
        label: "Tomorrow",
      },
      {
        value: new Date(
          Date.now() + 1000 /*sec*/ * 60 /*min*/ * 60 /*hour*/ * 24 /*day*/ * 7
        ),
        label: "7 Days",
      },
      {
        value: new Date(
          Date.now() + 1000 /*sec*/ * 60 /*min*/ * 60 /*hour*/ * 24 /*day*/ * 14
        ),
        label: "14 Days",
      },
      {
        value: new Date(
          Date.now() + 1000 /*sec*/ * 60 /*min*/ * 60 /*hour*/ * 24 /*day*/ * 30
        ),
        label: "30 Days",
      },
      {
        value: new Date(
          Date.now() + 1000 /*sec*/ * 60 /*min*/ * 60 /*hour*/ * 24 /*day*/ * 45
        ),
        label: "45 Days",
      },
      {
        value: new Date(
          Date.now() + 1000 /*sec*/ * 60 /*min*/ * 60 /*hour*/ * 24 /*day*/ * 60
        ),
        label: "60 Days",
      },
      {
        value: new Date(
          Date.now() + 1000 /*sec*/ * 60 /*min*/ * 60 /*hour*/ * 24 /*day*/ * 90
        ),
        label: "90 Days",
      },
      {
        value: "Custom",
        label: "Custom",
      },
    ]);

    const dueDate = ref(dueDates.value[3].value);
    const customDate = ref(null);
    const memo = ref("");
    const taxRate = ref([]);
    const taxRateItems = ref([]);
    const newTaxRate = ref("");
    const customer = reactive({
      id: null,
      name: "",
      email: "",
      phone: "",
      address: "",
    });
    const items = ref([]);
    const amountPaid = ref(0);
    const previewItems = reactive([]);

    const sendInvoice = () => {
      let date = dueDate.value;
      if (dueDate.value === "Custom") {
        date = customDate.value;
      }
      send({
        customerId: customer.id,
        items: items.value,
        description: memo.value,
        dueDate: new Date(date),
        taxRates: taxRate.value.length > 0 ? taxRate.value : null,
        amountPaid: amountPaid.value,
      });

      provide("new-invoice-created", true);
      newInvoiceCreated.value = true;

      clear();
    };

    const saveInvoiceAsDraft = () => {
      let date = dueDate.value;

      if (dueDate.value === "Custom") {
        date = customDate.value;
      }

      // todo : add validation
      saveAsDraft({
        customerId: customer.id,
        items: items.value,
        description: memo.value,
        dueDate: new Date(date),
        taxRates: taxRate.value.length > 0 ? taxRate.value : null,
        amountPaid: amountPaid.value,
      });

      newInvoiceCreated.value = true;
      clear();
    };

    const selectedCustomer = (item) => {
      customer.id = item.Id;
      customer.name = item.Name;
      customer.email = item.Email;
      customer.phone = item.Phone;
      customer.address = item.Address;
    };

    const selectedItems = (selectedItems) => {
      items.value = [];
      previewItems.value = selectedItems;

      selectedItems.forEach((item) => {
        items.value.push({
          productId: item.Id,
          quantity: parseInt(item.Quantity),
        });
      });
    };

    const onSendClose = () => {
      state.toggleSendModal = false;
    };

    const onSendSubmit = () => {
      sendInvoice();
      state.toggleSendModal = false;
    };

    const onSaveClose = () => {
      state.toggleSaveModal = false;
    };

    const onSaveSubmit = () => {
      saveInvoiceAsDraft();
      state.toggleSaveModal = false;
    };

    const handleAmountPaid = (value) => {
      amountPaid.value = value;
      // lover the total amount
      if (amountPaid.value > 0) {
        const total = previewItems.value.reduce(
          (acc, item) => acc + item.Price * item.Quantity,
          0
        );

        if (amountPaid.value > total) {
          amountPaid.value = total;
        }
      }
    };

    const clear = async () => {
      renderComponent.value = false;

      // Then, wait for the change to get flushed to the DOM
      await nextTick();

      // Add MyComponent back in
      renderComponent.value = true;

      memo.value = "";
      previewItems.value = [];
      customer.id = null;
      customer.name = "";
      customer.email = "";
      customer.phone = "";
      customer.address = "";
      items.value = [];
      dueDate.value = dueDates.value[3].value;
      amountPaid.value = 0;
      taxRate.value = [];
    };

    const isFormReady = computed(() => {
      if (customer.id === null || items.value.length === 0) {
        return false;
      }

      return true;
    });

    const onNewTaxRate = async () => {
      const response = await saveTaxRates({
        percentage: newTaxRate.value,
      });

      if (response.status === 200) {
        getTaxRates();
        state.toggleNewTaxRate = false;
      }
    };

    watch(
      () => taxRate.value,
      () => {
        if (taxRate.value.filter((x) => x === "new-tax-rate").length > 0) {
          state.toggleNewTaxRate = true;
          taxRate.value.pop();
        } else {
          taxRateItems.value = taxRates.value.filter((value) =>
            taxRate.value.includes(value.id)
          );
        }
      },
      { deep: true }
    );

    // customDate
    watch(customDate, (value) => {
      // if custom date is selected, set the dueDate to custom, but format the date
      if (value) {
        dueDate.value = "Custom";
      }
    });

    provide("new-invoice-created", readonly(newInvoiceCreated));

    onMounted(() => {
      getTaxRates();
    });

    return {
      customDate,
      memo,
      customer,
      checked1: ref(true),
      dueDates,
      state,
      dueDate,
      items,
      loading,
      sendInvoice,
      saveInvoiceAsDraft,
      selectedCustomer,
      selectedItems,
      onSendClose,
      onSendSubmit,
      onSaveClose,
      onSaveSubmit,
      previewItems,
      isFormReady,
      taxRates,
      taxRate,
      newTaxRate,
      onNewTaxRate,
      taxRateItems,
      amountPaid,
      handleAmountPaid,
      renderComponent,
      disabledDate,
    };
  },
  methods: {
    showSendModal() {
      this.show = true;
    },
  },
});
</script>

<style scoped>
.invoice {
  display: flex;
}

.invoice .preview {
  width: 660px;
  /* border: 1px solid #e6e6e6; */
  border-radius: 4px;
  padding: 8px;
  margin-left: 8px;
  height: 100%;
}

.invoice .form {
  padding-top: 8px;
  width: calc(100vw - 800px);
  flex-direction: column;
}

.invoice .form .form--item {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: 60%;
}

.invoice .form .form--item .customer {
  padding-top: 16px;
}

.header {
  white-space: normal;
  font-feature-settings: "pnum";
  font-variant: proportional-nums;
  line-height: 28px;
  font-weight: 700;
  font-size: 20px;
  color: rgb(60, 66, 87);
  text-align: left;
  margin-bottom: 16px;
}

.flex {
  display: flex;
}

.devider {
  border-top: 1px solid #ebebeb;
  margin: 8px 0;
}
</style>
