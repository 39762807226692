<template>
  <div v-loading="loading" class="layout mt-4">
    <div
      class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        Draft Invoices
      </h3>
      <div class="flex">
        <Search
          v-model:search="pagination.search"
          placeholder="Find customer"
        />
      </div>
    </div>

    <div class="flex flex-col" v-if="invoices.length > 0">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200 border">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Customer
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  ></th>

                  <th
                    class="bg-gray-50 px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Due
                  </th>
                  <th
                    class="bg-gray-50 px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Created
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody
                class="divide-y divide-gray-200 bg-white"
                v-if="invoices.length > 0"
              >
                <tr v-for="invoice in invoices" :key="invoice.Id">
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm font-medium text-gray-900">
                      <p
                        class="truncate text-gray-500 group-hover:text-gray-900"
                      >
                        {{ invoice.Customer.Name }}
                      </p>
                    </div>
                    <div class="max-w-sm truncate text-sm text-gray-500">
                      {{ invoice.Customer.Email }}
                    </div>
                  </td>

                  <td
                    class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                  >
                    <span class="font-medium text-gray-900"
                      >${{ invoice.AmountDue }}
                    </span>
                    USD
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      <span
                        class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                      >
                        Draft
                      </span>
                    </div>
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                  >
                    <time :datetime="invoice.DueDate">{{
                      new Date(invoice.DueDate).toLocaleDateString("en-us", {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                    }}</time>
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                  >
                    <time :datetime="invoice.Created">{{
                      new Date(invoice.Created).toLocaleDateString("en-us", {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                    }}</time>
                  </td>
                  <td
                    class="flex justify-end space-x-3 whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                  >
                    <!-- <button
                      type="button"
                      v-tippy="{
                        content: 'Edit',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      @click="onEdit(invoice)"
                      class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-purple-400 hover:text-purple-800 focus:outline-none  focus:ring-purple-500"
                    >
                      <PencilIcon class="flex-shrink-0 h-5 w-5 text-gray-400" />
                    </button> -->
                    <button
                      type="button"
                      v-tippy="{
                        content: 'Send',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      @click="onSend(invoice)"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-purple-500"
                    >
                      <PaperAirplaneIcon
                        class="h-5 w-5 flex-shrink-0 text-purple-400"
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <pagination :pagination="pagination" @changePage="onChangePage" />
          </div>
        </div>
      </div>
    </div>
    <el-empty
      class="mt-8"
      v-else
      description="No draft invoices found"
    ></el-empty>
  </div>
  <ConfirmModal
    v-if="state.toggleSend"
    :show="state.toggleSend"
    @onClose="onClose"
    @onSubmit="onSubmit"
  />
  <EditInvoice
    v-if="state.openModal"
    :show="state.openModal"
    :invoice="state.invoice"
    @onClose="closeUpdateInvoice"
    @onSubmit="onUpdateinvoice"
  />
</template>

<script>
import { onMounted, reactive, watch } from "vue";

import useInvoicesRepository from "@/composable/useInvoicesRepository";
import ConfirmModal from "./components/_confirmModal";
import EditInvoice from "./components/_edit";
import Pagination from "@/common/components/pagination";
import Search from "@/common/components/search";
import { PaperAirplaneIcon } from "@heroicons/vue/outline";

import { directive } from "vue-tippy";
import "tippy.js/animations/scale.css";

export default {
  components: {
    Pagination,
    ConfirmModal,
    EditInvoice,
    PaperAirplaneIcon,
    Search,
  },
  directives: {
    tippy: directive,
  },
  setup() {
    const { send, loading, invoices, totalResults, getDraftInvoices } =
      useInvoicesRepository();

    const state = reactive({
      invoice: null,
      toggleSend: false,
      toggleDraft: false,
    });

    const pagination = reactive({
      page: 1,
      pageSize: 10,
      search: "",
      totalResults: totalResults,
    });

    const onChangePage = (page) => {
      pagination.page = page;
      getDraftInvoices(pagination.page, pagination.pageSize, pagination.search);
    };

    const onSend = (invoice) => {
      state.invoice = invoice;
      state.toggleSend = true;
    };

    const onClose = () => {
      state.toggleSend = false;
    };

    const onSubmit = () => {
      send({
        id: state.invoice.Id,
        customerId: state.invoice.Customer.Id,
        items: state?.invoice?.Items?.reduce((acc, item) => {
          acc.push({
            productId: item.Id,
            quantity: item.Quantity,
          });
          return acc;
        }, []),
        description: state.invoice.Description,
        dueDate: state.invoice.DueDate,
      });
      state.toggleSend = false;
    };

    const onEdit = (invoice) => {
      state.invoice = invoice;
      state.openModal = true;
    };

    onMounted(() => {
      getDraftInvoices(pagination.page, pagination.pageSize, "");
    });

    watch(
      () => pagination.search,
      (search) => {
        getDraftInvoices(1, 10, search);
      },
      { deep: true }
    );

    return {
      send,
      onSend,
      onEdit,
      onClose,
      onSubmit,
      state,
      loading,
      invoices,
      pagination,
      onChangePage,
    };
  },
};
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  /* overflow-y: scroll; */
  width: 100%;
  position: relative;
}
</style>
