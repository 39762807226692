<template>
  <Slideover title="Edit Invoice"> </Slideover>
</template>

<script>
import { reactive, toRefs } from "vue";

import Slideover from "@/common/components/slideover";

export default {
  components: {
    Slideover,
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const state = reactive({
      count: 0,
      open: true,
    });

    return {
      state,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped></style>
